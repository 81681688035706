import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { Can } from '../../Components/Can'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useParams } from 'react-router-dom'
import { departmentOptions, officesOptions } from './create'

export interface CollaboratorProps {
  id: string
  office: string
  name: string
  email: string
  department: string
  officeLabel: string
}

const CollaboratorsList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CollaboratorProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as CollaboratorProps)
  const [deactivatedItem, setDeactivatedItem] = useState({} as CollaboratorProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag
        }
      })

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar os  collaboradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/collaborators/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as CollaboratorProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as CollaboratorProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as CollaboratorProps)
  }, [])

  const closeDeactivationModal = useCallback(() => {
    setDeactivatedItem({} as CollaboratorProps)
  }, [])

  const handleFilterList = useCallback(async (e: any) => {
    setLoading(true)
    console.log('e: ', e?.target?.value)
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          name: e?.target?.value
        }
      })

      setPage(1)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Can permissions={['collaborators.list', 'collaborators.create', 'collaborators.all']} page>
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Usuário"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

{!!deactivatedItem?.id && (
        <AlertDialogCustom
          title="Desativar Usuário"
          isOpen={!!deactivatedItem?.id}
          cancelFunction={closeDeactivationModal}
          nextFunction={hanleDelete}
        />
)}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black.primary">
              Colaboradores
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por nome" onChangeFunction={handleFilterList}/>

            <Button
              as="a"
              href="/collaborators/create"
              size="sm"
              fontSize="sm"
              bg="black.primary"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar novo colaborador
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de colaboradores</Text>
            </Flex>
                )
              : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center">Nome</Th>
                    <Th textAlign="center">Departamento</Th>
                    <Th textAlign="center">Cargo</Th>
                    <Th textAlign="center">Email</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{departmentOptions?.find(d => d.value === i.department)?.label}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{officesOptions?.find(d => d.value === i.office)?.label}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center">{i.email}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>
                        {/* {isWideScreen
                          ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                              onClick={() => setDeactivatedItem(i)}
                            >
                              Desativar
                            </Button>
                            )
                          : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="desativar"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                            )} */}

                          {isWideScreen
                            ? (
                            <Button
                            as="a"
                            href={`/collaborators/create/${i.id}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="wine.200"
                              colorScheme="wine"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
                )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { CollaboratorsList }
