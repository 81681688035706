import { Flex, Box, Avatar, Text } from '@chakra-ui/react'
import { useAuth } from '../../Hooks/Auth'

interface ProfileProps {
  showProfileData?: boolean
}
export function Profile ({ showProfileData = true }: ProfileProps) {
  const { user } = useAuth()

  return (
        <Flex align="center">
            {showProfileData && (
                 <Box
                 mr="4" textAlign="right">
                 <Text color="black.primary">{user.name}</Text>
                 <Text color="black.primary" fontSize="small">
                     {user.email}
                 </Text>
             </Box>
            )}

        <Avatar size="md" name="Diego Hennrich"/>
    </Flex>
  )
}
