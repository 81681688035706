import React, { FC, useState, useEffect } from 'react'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import { Flex, SimpleGrid, Box, Text, theme } from '@chakra-ui/react'

import { useAuth } from '../../Hooks/Auth'
import { TilesBox } from '../../Components/Tiles/TilesBox'
import { FiUsers } from 'react-icons/fi'
import { MdVideoCall, MdAttachMoney, MdRemoveRedEye, MdOutlineBusinessCenter } from 'react-icons/md'
import api from '../../Services/api'
import { BsNewspaper } from 'react-icons/bs'

interface DataProps {
  users: number
  videos: number
}

const Dashboard: FC = () => {
  const [data, setData] = useState({} as DataProps)

  useEffect(() => {
    api.get('/overview').then(resp => {
      const dados = resp.data

      setData(dados)
    }).catch(error => console.log('error useEffect AuthProvider: ', error))
  }, [])

  return (
    <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">
          <Sidebar />

        </Flex>
      </Flex>

    </Flex>
  )
}

export { Dashboard }
