import axios from 'axios'

// https://www.geartag.com.br/api
// http://localhost:21333/api
const api = axios.create({
  baseURL: 'https://www.geartag.com.br/api'
})

export const adminUrl = 'https://www.geartag.com.br/api/admin/queues/'

export default api
