/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { DragAndDrop } from '../../Components/Form/DragAndDrop'
import { RiAddLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { SelectWithSearch } from '../../Components/Form/SelectWithSearch'
import IProcessProps, { IEachPaymentProps, IPaymentStatus } from './Interfaces/ProcessIntefaces'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { ModalNewBill } from './ModalNewBill'
import { PaymentRequestModal } from '../../Components/PaymentRequestModal'
import { AiFillEye } from 'react-icons/ai'
import { ReceiptPaymentModal } from '../../Components/ReceiptPaymentModal'
import { Loader } from '../../Components/Loader'
import { PaymentRefuseModal } from '../../Components/PaymentRefuseModal'
import { Can } from '../../Components/Can'

const optionsPrefectures = [
  {
    value: 'SIMPROC',
    label: 'SIMPROC'
  },
  {
    value: 'SEI',
    label: 'SEI'
  }
]

interface ItemProposalProps {
  id: string
  executor_value: string
  executor_id: string
  service_id: string
  payment_service_id: string
  ticket_value: string
  expenses_value: string
}

const ProcessCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [showAddressComponent, setShowAddressComponent] = useState(false)
  // const [items, setItems] = useState<ItemProps[]>([])

  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigation = useNavigate()
  const [processData, setProcessData] = useState<IProcessProps>({} as IProcessProps)
  const [selectedPayment, setSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [receiptSelectedPayment, setReceiptSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [refusedPayment, setRefusedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [showNewBillModal, setShowNewBillModal] = useState(false)

  const allowedReceiptValues = ['PAID', 'REQUESTED', 'REFUSED', 'WAITING_PERMISSION']

  const [items, setItems] = useState<ItemProposalProps[]>([
    {
      id: 'aaa',
      executor_value: '1500.00',
      executor_id: '976e0565-c55f-4900-bd24-f4aa71e33be6',
      service_id: 'e23a148f-1bd9-45e4-847a-638313b87f3b',
      payment_service_id: '5200a899-4545-4a5b-a362-0f7910533776',
      ticket_value: '40.00',
      expenses_value: '200.00'
    },
    {
      id: 'bbb',
      executor_value: '2000',
      executor_id: '739cac3e-54a4-4c3d-a832-b0160bfb2ae0',
      service_id: '1331cf7d-d63d-46db-9a12-65f3d4c2beb9',
      payment_service_id: '50ee0964-f31f-49b7-b79c-d0109bddf21b',
      ticket_value: '',
      expenses_value: ''
    }
  ])

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('values: ', values)
      console.log('items: ', items)
    },
    [toast, items, params]
  )

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/process/${params?.id}`, {
        params: {
          paymentKind: 0
        }
      })

      setProcessData(data)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, params])

  useEffect(() => {
    load()
  }, [])

  const handlePayment = useCallback(async () => {
    const { id } = selectedPayment
    setLoading(true)
    try {
      const { data } = await api.patch(`/payments/${id}?kind=0`)
      console.log('response handlePlayment: ', data)
      const wasAgended = data?.payment?.allowed_at
      const payment_id = data?.payment?.id

      setSelectedPayment({} as IEachPaymentProps)

      return { payment_id, wasAgended }
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedPayment({} as IEachPaymentProps)
    }
  }, [selectedPayment, toast])

  const closeModal = useCallback(() => {
    setSelectedPayment({} as IEachPaymentProps)
  }, [])

  const makePaymentButtonColor = useCallback((statusLabel: IPaymentStatus) => {
    if (statusLabel === 'CREATED') {
      return 'pink.500'
    } else if (statusLabel === 'REQUESTED') {
      return 'teal.500'
    } else if (statusLabel === 'REFUSED') {
      return 'red.500'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return 'yellow.500'
    } else {
      return 'gray.500'
    }
  }, [])

  const makeLabelPayment = useCallback((statusLabel: IPaymentStatus, date: string | null) => {
    if (statusLabel === 'CREATED') {
      return 'Solicitar Pagamento'
    } else if (statusLabel === 'REQUESTED') {
      return 'Pagamento Solicitado'
    } else if (statusLabel === 'REFUSED') {
      return 'Pagamento Recusado'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return `Pagamento Agendado: ${date ?? ''}`
    } else {
      return 'Pagamento efetuado'
    }
  }, [])

  const handleNewTaxOrRefund = useCallback(async () => {
    try {
      // const { data } = await api.post(`/payments/${params?.id}`)

      // const wasAgended = data?.payment?.allowed_at

      // toast({
      //   title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
      //   position: 'top',
      //   isClosable: true,
      //   status: 'success',
      //   variant: 'solid'
      // })
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const makeButtonFunction = useCallback((payment: IEachPaymentProps) => {
    if (payment.statusLabel === 'CREATED') {
      return () => setSelectedPayment(payment)
    }

    if (payment.statusLabel === 'REFUSED') {
      return () => setRefusedPayment(payment)
    }

    return () => {}
  }, [])

  const handleReprocessPayment = useCallback(async () => {
    try {
      setLoading(true)

      await api.put(`/payments/${refusedPayment?.id}`, {
        status: 'CREATED'
      })

      toast({
        title: 'Pagamento Resetado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setRefusedPayment({} as IEachPaymentProps)
      setLoading(false)
    }
  }, [toast, load, refusedPayment])

  return (
    <Can permissions={['proccess.list', 'proccess.create', 'proccess.all']} page>
    <Box>
      {loading && <Loader />}
      <Header />

      {showNewBillModal && <ModalNewBill isOpen={showNewBillModal} title="Solicitação de Pagamento - Nova Conta" cancelFunction={() => setShowNewBillModal(false)} nextFunction={handleNewTaxOrRefund} />}

      {
        !!selectedPayment?.id && (
        <PaymentRequestModal
        title="Solicitar Pagamento"
        isOpen={!!selectedPayment?.id}
        cancelFunction={closeModal}
        nextFunction={handlePayment}
        cbFunction={async () => {
          await load()
          setLoading(false)
        }}
        />
        )
      }

      {
        !!receiptSelectedPayment?.id && (
          <ReceiptPaymentModal
          title="Recibos"
          isOpen={!!receiptSelectedPayment?.id}
          cancelFunction={() => setReceiptSelectedPayment({} as IEachPaymentProps)}
          nextFunction={() => {}}
          cbFunction={async () => await load()}
          files={receiptSelectedPayment.files}
          />
        )
      }

      {!!refusedPayment?.id && (
        <PaymentRefuseModal
          title="Pagamento Recusado"
          isOpen={!!refusedPayment?.id}
          cancelFunction={() => setRefusedPayment({} as IEachPaymentProps)}
          nextFunction={handleReprocessPayment}
          refused
          initialRefusedReason={refusedPayment?.refused_reason}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="black.primary">
            Processos
          </Heading>

          <Tabs variant='enclosed' mt="10">
            <TabList>
            <Tab>Proposta e Pagamentos</Tab>
              <Tab>Processo</Tab>

              <Tab>Taxas e Reembolso</Tab>
            </TabList>
            <TabPanels>

              <TabPanel>

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Dados Cadastrais
                </Heading>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                      <Text align="center">{processData?.technical?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                      <Text align="center">{processData?.financial?.name}</Text>
                  </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Rede</Text>
                      <Text align="center">{processData?.net?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Unidade</Text>
                      <Text align="center">{processData?.unit?.name}</Text>
                  </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Items da Proposta
                </Heading>

                {processData?.items?.map((item, indexItem: number) => (
                    <VStack spacing={['9', '9']} mb="5" bg="black.primary" p="10" key={item.id} borderRadius="8px">

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">
                          <Box>
                            <Text color="white.primary" fontWeight={500} align="left">Serviço</Text>
                            <Text align="left" color="white.primary">{item?.service?.name}</Text>
                          </Box>
                      </SimpleGrid>

                      <Divider color="white" />

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                            <Text align="center" color="white.primary">{item?.executor?.name}</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                            <Text align="center" color="white.primary">{item?.installments}x</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Valor Total</Text>
                            <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                          </Box>

                      </SimpleGrid>

                        <Divider color="white" />

                        {processData?.payments[item.id]?.map(payment => (
                          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" key={payment.id}>

                            <Box>
                              <Text color="white.primary" fontWeight={500} align="center">Parcela {payment.installment}: {payment.provider_value_installment_formatted}</Text>

                            </Box>

                            {allowedReceiptValues.includes(payment.statusLabel)
                              ? (
                              <Button
                                type="button"
                                onClick={() => setReceiptSelectedPayment(payment)}
                                bg="blue.400"
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"
                                leftIcon={<Icon as={AiFillEye} fontSize="19" />}

                              >
                                Recibos
                            </Button>
                                )
                              : <Box />}

                            <Button
                                type="button"
                                onClick={makeButtonFunction(payment)}
                                bg={makePaymentButtonColor(payment.statusLabel)}
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"

                              >
                                {makeLabelPayment(payment.statusLabel, payment.allowed_at_formatted)}
                            </Button>
                          </SimpleGrid>
                        ))}

                    </VStack>
                ))}
              </TabPanel>

              <TabPanel>
                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="10">
                        <Input

                                  label="Número do processo na Prefeitura"
                                  placeholder="Preenchar o número"
                                  // errors={errors.name}
                                  {...register('name-item-')}
                                />

                        <Select

                                  label="Selecionar Prefeitura"
                                  placeholder="Selecionar"
                                  // errors={errors.gender}
                                  {...register('executante-item-')}
                                  options={optionsPrefectures}
                                  />
                  </SimpleGrid>

                  <Button
                  type="submit"
                  bg="black.primary"
                  _hover={{ bgColor: 'gray.500' }}
                  color="white"
                  isLoading={formState.isSubmitting}
                  mt="10"
                  >
                    Salvar
                  </Button>
              </TabPanel>

              <TabPanel>
              <Button
                               onClick={() => setShowNewBillModal(true)}
                                as="button"
                                size="sm"
                                fontSize="sm"
                                mr="2"
                                bg="wine.200"
                                colorScheme="wine"
                                ml="auto"
                                leftIcon={
                                  <Icon as={RiAddLine} fontSize="16" />
                                }
                              >
                              Adicionar nova conta avulsa
                            </Button>
              </TabPanel>

            </TabPanels>
          </Tabs>

        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ProcessCreate }
