/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, RefObject, useCallback, useMemo, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  FormLabel,

  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'
import { DragAndDrop, DragAndDropExportedProps } from '../Form/DragAndDrop'
import { ReceiptPreview } from '../ReceiptPreview'
import { FilesProps } from '../../Pages/Process/Interfaces/ProcessIntefaces'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  cbFunction?: (data?: any) => Promise<any>
  files: FilesProps[]
}

const ReceiptPaymentModal: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  cbFunction,
  files
}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)

  const { register, handleSubmit, formState } = useForm({
    // resolver: yupResolver(ResetPassSchema)
  })
  const toast = useToast()
  const { user, updateGlobalUser } = useAuth()

  const { errors } = formState

  const DragRef = useRef<DragAndDropExportedProps>(null)
  const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async () => {
      setLoading(true)

      try {
        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: 'Anexo obrigatório.',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        const { payment_id, wasAgended } = await nextFunction()

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? async () => {
              // navigate('/collaborators')

              toast({
                title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })

              if (cbFunction != null) {
                await cbFunction()
                setLoading(false)
              }
            }
            : () => {}
          inputDrag?.current?.execute(`/files/${payment_id}`, cb)
        }))
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [nextFunction, toast, allFiles, cbFunction]
  )

  const makeLabel = (type: string): string => {
    const customLabel: any = {
      'comprovante-execucao-servico': 'Nota Fiscal / Comprovante de Execução do Serviço',
      'comprovante-pagamento': 'Comprovante de Pagamento'
    }

    return customLabel[type] || ''
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="5xl"
    >
        {loading && <Loader />}
      <AlertDialogOverlay onClick={cancelFunction} >

      <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          p="8"
        >

        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Confira abaixo todos os comprovantes desta operação:</Text>
          <VStack spacing={['6', '8']} mt="10">
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              {files.map(file => <ReceiptPreview label={makeLabel(file.type)} key={file.id} defaultCover={file.link} />)}

              {files?.length < 2 && <Box />}

            </SimpleGrid></VStack>
          </AlertDialogBody>

          <AlertDialogFooter>

            {/* <Button
            type='submit'
              colorScheme={'pink'}

              ml={3}
            >
             Solicitar Pagamento
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { ReceiptPaymentModal }
