import React, { FC, useRef, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { TextArea } from '../../Components/Form/TextArea'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'

interface createCategory {
  index?: number
  name: string
  description: string
}

interface OptionsProps {
  label: string
  value: string
}

const createFormSchema = Yup.object().shape({
  qtd: Yup.string().required('Quantidade é obrigatório')
})

const updateFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório')
})

const TagsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)
  const [category, setCategory] = useState<any>({})
  const params = useParams()

  const toast = useToast()
  const DragRef = useRef<DragAndDropExportedProps>(null)
  console.log('params: ', params)
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handeCreateTag: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      try {
        const { qtd } = values

        await api.post('/tags', { qtd })

        toast({
          title: 'Tags criadas com sucesso',
          position: 'top',
          isClosable: true,
          status: 'info',
          variant: 'solid'
        })

        navigate('/tags')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [toast, navigate]
  )

  const loadItem = useCallback(async () => {
    try {
      const { slug } = params
      if (!slug) return
      const resp = await api.get(`/categories/${slug}`)
      const data: any = resp.data
      console.log('data edit: ', data)
      setValue('name', data.name)
      setValue('description', data.description)
      setCategory(data)

      if (data.cover_url) {
        setInitialCover(data.cover_url)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params.slug) {
      loadItem()
    }
  }, [params])

  return (
    <Box>
      <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">

        <Sidebar />

        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handeCreateTag)}
        >
          <Heading size="lg" fontWeight="normal" color="teal">
            Criar novas Tags
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <VStack spacing={['9', '9']}>
            <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
              <Input
                label="Quantas tags você deseja criar?"
                placeholder="Apenas números"
                errors={errors.qtd}
                {...register('qtd')}
                type='number'
              />
            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              {loading
                ? (
                <Spinner />
                  )
                : (
                <>
                  <Button
                    as="a"
                    bg="teal"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    bg="black"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                  >
                    Salvar
                  </Button>
                </>
                  )}
            </HStack>
          </Flex>
        </Box>
      </Flex>
      </Flex>
      </Flex>
    </Box>
  )
}

export { TagsCreate }
