/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box, Divider,
  Flex,
  Heading, Icon, SimpleGrid, Text, useToast
} from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FiCheck, FiCheckCircle, FiX } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Can } from '../../Components/Can'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import IPaymentProps from './Interfaces/ProcessIntefaces'

interface OptionsProps {
  label: string
  value: string
}

interface ItemsSelected {
  id: string
  label: string
  value: string
}

interface ItemsForPaymentsOptions {
  id: string
  options: OptionsProps[]

}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido')
    .required('E-mail é obrigatório'),
  born: Yup.string().required('Data de nascimento é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),

  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade'),
  zip_code: Yup.string(),
  street: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    })
})

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

const optionsPrefectures = [
  {
    value: 'SIMPROC',
    label: 'SIMPROC'
  },
  {
    value: 'SEI',
    label: 'SEI'
  }
]

interface ItemProposalProps {
  id: string
  executor_value: string
  executor_id: string
  service_id: string
  payment_service_id: string
  ticket_value: string
  expenses_value: string
}

interface PaymentsProps {
  id: string
  name: string
}

interface ServicesProps {
  id: string
  cod: string
  name: string
  service: string
  value: string
  payments: PaymentsProps[]
}

const BillsToReceiveCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [showAddressComponent, setShowAddressComponent] = useState(false)
  // const [items, setItems] = useState<ItemProps[]>([])

  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigation = useNavigate()
  const [paymentData, setPaymentData] = useState<IPaymentProps>({} as IPaymentProps)
  const [showPaidModal, setShowPaidModal] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)
  const statusOptions = [
    {
      value: 'REQUESTED',
      label: 'Solicitado ao Cliente - Aguardando Pagamento'
    },
    {
      value: 'PAID',
      label: 'Pago'
    },
    {
      value: 'CREATED',
      label: 'Criado'
    }
  ]

  const typeOptions = {
    HONORARY: 'Honorários',
    TAX: 'Taxa',
    REFUND: 'Reembolso'
  }

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const DragRef = useRef<DragAndDropExportedProps>(null)

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {

    },
    [toast, navigation, params]
  )

  const load = useCallback(async () => {
    try {
      const { data } = await api.get(`/payments/${params?.id}`)
      setValue('status', data.statusLabel)
      setPaymentData(data)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, params])

  useEffect(() => {
    load()
  }, [])

  const handlePayment = useCallback(async () => {
    try {
      const { data } = await api.patch(`/payments/${params?.id}`)

      const wasAgended = data?.payment?.allowed_at

      toast({
        title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      // setSelectedPayment({} as IEachPaymentProps)
      // setPaymentData(prev => {
      //   const paymentsOfThisItemId = prev.payments[id]
      //   const findIndex = paymentsOfThisItemId.findIndex(i => i.id === id)
      //   paymentsOfThisItemId[findIndex] = {
      //     ...paymentsOfThisItemId[findIndex],
      //     allowed_at: format(new Date(wasAgended), 'dd/MM/yyyy')
      //   }

      //   const payload = {
      //     ...prev
      //   }

      //   payload.payments[id] = paymentsOfThisItemId

      //   return payload
      // })
      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      // setSelectedPayment({} as IEachPaymentProps)
    }
  }, [toast, load])

  const closeModal = useCallback(() => {
    setShowPaidModal(false)
  }, [])

  const handleConfirmPayment = useCallback(async () => {
    try {
      const values = getValues()
      console.log('values: ', values)
      await api.put(`/payments/${params?.id}`, {
        status: values.status
      })

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, params, getValues, load])

  const handleChangePaymentStatus = useCallback((e: any) => {
    const selectValue = e?.target?.value

    if (selectValue === 'PAID') {
      setShowPaidModal(true)
    }
  }, [])

  return (
    <Can permissions={['bills_to_receive.create', 'bills_to_receive.all']} page>
    <Box>
      <Header />

      {showPaidModal && (
        <AlertDialogCustom
          title="Confirmar Pagamento"
          isOpen={showPaidModal}
          cancelFunction={closeModal}
          nextFunction={handleConfirmPayment}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >

          <Flex justifyContent="space-between">
          <Heading size="lg" fontWeight="normal" color="black.primary">
            Pagamento: <b>{paymentData?.valueFormatted}</b>
          </Heading>
            <Box>
            <Text color="black.primary" fontWeight={500} align="left" >Pagamento Solicitado por:</Text>
          <Text align="left">{paymentData?.collaborator?.name}</Text>
            </Box>

          </Flex>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Dados Operacionais
          </Heading>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Nº Processo/OS</Text>
                <Text align="center">{paymentData?.process?.cod_prefecture}</Text>
            </Box>

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Nº Proposta</Text>
                <Text align="center">{paymentData?.proposal?.number}</Text>
            </Box>

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Rede</Text>
                <Text align="center">{paymentData?.net?.name}</Text>
            </Box>

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Unidade</Text>
                <Text align="center">{paymentData?.unit?.name}</Text>
            </Box>

          </SimpleGrid>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Serviço Prestado</Text>
                <Text align="center">{paymentData?.service?.name}</Text>
            </Box>

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Executor</Text>
                <Text align="center">{paymentData?.provider?.name}</Text>
            </Box>

            <Box>
              <Text color="black.primary" fontWeight={500} align="center">Tipo</Text>
              <Text align="center">{typeOptions[paymentData?.typeLabel]}</Text>
          </Box>

            <Box>
                <Text color="black.primary" fontWeight={500} align="center">Verificado</Text>
                <Text align="center">{paymentData?.provider?.active === 0 ? <Icon as={FiCheck} fontSize="24" color="#03ac13" /> : <Icon as={FiX} fontSize="24" color="#FF0000" />}</Text>
            </Box>

          </SimpleGrid>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="200px">

          <Box>
                <Text color="black.primary" fontWeight={500} align="center">Valor à Receber</Text>
                <Text align="center" fontWeight="bold">{paymentData?.valueFormatted}</Text>
            </Box>

          <Box>
              <Text color="black.primary" fontWeight={500} align="center">Parcela</Text>
              <Text align="center">{paymentData?.installment}</Text>
          </Box>

          <Box>
              <Text color="black.primary" fontWeight={500} align="center">-</Text>

          </Box>

          <Box>
              <Text color="black.primary" fontWeight={500} align="center">-</Text>

          </Box>
          </SimpleGrid>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Atualizar Status de Pagamento
          </Heading>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">
              <Select {...register('status')} options={statusOptions} defaultValue={paymentData.statusLabel} onChange={handleChangePaymentStatus} />
          </SimpleGrid>

          {paymentData?.statusLabel === 'PAID' && (
            <>
            <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Comprovante
              </Heading>

              <Box>
                  <DragAndDrop
                    label="Comprovante de pagamento"
                    ref={DragRef}
                    defaultCover={initialCover}
                  />
              </Box>
              </>
          )}

        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { BillsToReceiveCreate }
