import React from 'react'
import { Stack } from '@chakra-ui/react'
import { NavSection } from './NavSection'
import { NavLink } from './NavLink'
import {
  RiDashboardLine,
  RiContactsLine,
  RiInputMethodLine,
  RiGitMergeLine,
  RiCurrencyFill
} from 'react-icons/ri'
import { BsCalendarDate, BsNewspaper } from 'react-icons/bs'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { MdSecurity, MdOutlineBusinessCenter, MdAttachMoney } from 'react-icons/md'
import { CgGym } from 'react-icons/cg'
import { Can } from '../Can'
import { adminUrl } from '../../Services/api'

export function NavSidebar () {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">

          <NavLink href="/dashboard" icon={RiDashboardLine}>
            Dashboard
          </NavLink>

        <Can>
          <NavLink href="/tags" icon={RiContactsLine}>
            Tags
          </NavLink>
        </Can>

        <Can>
          <NavLink href="/users" icon={HiOutlineDocumentText}>
            Usuários
          </NavLink>
        </Can>

        <Can>
          <NavLink href={adminUrl} icon={MdSecurity}>
            Processamento
          </NavLink>
        </Can>

        <Can permissions={['bills_to_pay.list', 'bills_to_pay.create', 'bills_to_pay.all']}>
          <NavLink href="/bills-pay" icon={RiCurrencyFill}>
            Contas à Pagar
          </NavLink>
        </Can>

        <Can permissions={['bills_to_receive.list', 'bills_to_receive.create', 'bills_to_receive.all']}>
          <NavLink href="/bills-receive" icon={RiCurrencyFill}>
            Contas à Receber
          </NavLink>
        </Can>

        {/* <Can permissions={['all.list']}>
          <NavLink href="/financial" icon={RiCurrencyFill}>
            Contas à Receber
          </NavLink>
        </Can> */}

<Can permissions={['pre_proposal.list', 'pre_proposal.create', 'pre_proposal.all']}>
          <NavLink href="/pre-proposal" icon={MdSecurity}>
            Pré-Propostas
          </NavLink>
        </Can>

        <Can permissions={['proposal.list', 'proposal.create', 'proposal.all']}>
          <NavLink href="/proposal" icon={MdSecurity}>
            Propostas
          </NavLink>
        </Can>

        <Can permissions={['services.list', 'services.create', 'services.all']}>
          <NavLink href="/services" icon={BsCalendarDate}>
            Tipos de Serviços
          </NavLink>
        </Can>

        <Can permissions={['clauses.list', 'clauses.create', 'clauses.all']}>
          <NavLink href="/clause-proposal" icon={AiOutlineClockCircle}>
            Cláusulas de Proposta
          </NavLink>
        </Can>

        {/* <Can permissions={["all.list"]}>
          <NavLink href="/schedule" icon={FaRegCalendarAlt}>
            Agenda
          </NavLink>
        </Can> */}
        {/* <NavLink href="/partners" icon={MdOutlineBusinessCenter}>Parceiros</NavLink>
                <NavLink href="/vacancys" icon={BsNewspaper}>Vagas</NavLink>
                <NavLink href="/financial" icon={MdAttachMoney}>Financeiro</NavLink> */}
      </NavSection>

      {/* <Can permissions={["all.list"]}>
        <NavSection title="APLICATIVO">

          <NavLink href="/app-config/create" icon={RiGitMergeLine}>
            Configurações
          </NavLink>
        </NavSection>
      </Can> */}
    </Stack>
  )
}
