/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text,
  Icon
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { RiAddLine, RiCloseFill } from 'react-icons/ri'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { Can } from '../../Components/Can'

interface PaymentsProps {
  name: String
}
interface CreateServiceProps {
  cod: string
  service: string
  value: string | number
  name: string
}

interface OptionProps {
  value: string
  label: string
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome do serviço é obrigatório'),
  service: Yup.string().required('Nome Oficial do serviço é obrigatório'),
  installments: Yup.string().required('Selecione o número máximo de parcelas')
})

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

interface ItemProps {
  id: string
  name: string
}

let controlRender = false

const ServicesCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [items, setItems] = useState<ItemProps[]>([])
  const params = useParams()

  const toast = useToast()
  const navigate = useNavigate()

  const installmentsOptions = [
    {
      value: '1',
      label: '1'
    },
    {
      value: '2',
      label: '2'
    },
    {
      value: '3',
      label: '3'
    },
    {
      value: '4',
      label: '4'
    },
    {
      value: '5',
      label: '5'
    },
    {
      value: '6',
      label: '6'
    },
    {
      value: '7',
      label: '7'
    },
    {
      value: '8',
      label: '8'
    },
    {
      value: '9',
      label: '9'
    },
    {
      value: '10',
      label: '10'
    },
    {
      value: '11',
      label: '11'
    },
    {
      value: '12',
      label: '12'
    }
  ]

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('values: ', values)

      const payload = {
        ...values,
        installments: values?.installments ?? '1'

      }

      const { service_id } = params

      try {
        service_id
          ? await api.put(`/services/${service_id}`, payload)
          : await api.post('/services', payload)

        toast({
          title: `${service_id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigate('/services')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigate]
  )

  const loadItem = useCallback(async (service_id: string) => {
    if (controlRender) return
    controlRender = true

    try {
      const { data } = await api.get(`/services/${service_id}`)

      // setValue('cod', data.cod)
      setValue('name', data.name)
      setValue('service', data.service)
      setValue('value', Number(data.value))
      setValue('installments', data.installments)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  useEffect(() => {
    if (params?.service_id) {
      loadItem(params.service_id)
    }
  }, [])

  return (
    <Can permissions={['services.create', 'services.all']} page>
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="black.primary">
            Criar novo Serviço
          </Heading>
          <Divider my="6" borderColor="black.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            {/* <Input
                label="Código Item"
                placeholder="Preencha o código"
                // errors={errors.name}
                {...register('cod')}
              /> */}

              <Input
                label="Serviço Prestado"
                placeholder="Preencha o serviço"
                errors={errors.name}
                {...register('name')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              {/* <MoneyInput

                label="Valor de referência"
                placeholder="Preencha o valor"
                // errors={errors.name}
                {...register('value')}
              /> */}

              <Select
                label="Número máximo de parcelas"
                placeholder="Selecionar Parcelas"
                errors={errors.installments}
                {...register('installments')}
                options={installmentsOptions}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <TextArea
                label="Nome Oficial do Serviço Prestado"
                placeholder="Preencha"
                {...register('service')}
                errors={errors.service}
              />

            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="black.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ServicesCreate }
