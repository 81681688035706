export const PROPOSAL_STATUS = {
  CREATED: 0,
  APPROVED_BY_COMPANY: 1, // projecto inseriu seus valores e salvou a proposta
  APPROVED_BY_CUSTOMER: 2, // projeto enviado e aprovado pelo cliente, pode gerar o processo
  REFUSED: 3,
  PROCESSED: 4, // usando quando a ordem de serviço é criada
  FINISHED: 4 // usado quando o processo termina e não há mais nada para fazer no projeto
}

export const COLLABORATOR_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const PROVIDERS_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const NOTIFICATIONS_TYPE = {
  'pre-proposal-approvement-boss-sector': 'pre-proposal-approvement-boss-sector',
  'provider-create-boss-sector': 'provider-create-boss-sector',
  info: 'info'
}
