import React, { FC, useCallback, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  SimpleGrid,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'
import { TextArea } from '../Form/TextArea'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
}

const RefusePreProposalModal: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  nextScheme
}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState } = useForm({
    // resolver: yupResolver(ResetPassSchema)
  })
  const toast = useToast()

  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        message
      } = values

      try {
        await nextFunction(message)
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [nextFunction, toast]
  )

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="5xl"
    >
        {loading && <Loader />}
      <AlertDialogOverlay>
      <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          p="8"
        >
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
          <VStack spacing={['6', '8']}>
          <TextArea
                label="Motivo"
                placeholder="Preencha o motivo da recusa"
                errors={errors.message}
                height={300}
                {...register('message')}

              />
          </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
          <Button
              type='button'
              colorScheme={nextScheme || 'red'}
             onClick={cancelFunction}
              ml={3}
            >
             Cancelar
            </Button>

            <Button
            type='submit'
              colorScheme={'teal'}

              ml={3}
            >
             Recusar Pré-Proposta
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { RefusePreProposalModal }
