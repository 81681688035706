import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { Can } from '../../Components/Can'

interface PreProposal {
  id: string
  number: string
  net: {
    name: string
  }
  unit: {
    name: string
  }
  created_at: string
}

const ProposalList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<PreProposal[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as PreProposal)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/proposal', {
        params: {
          pag,
          approved: 1
        }
      })

      const normalize = data.data.map((i: any) => {
        // const regExpPhone = new RegExp(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/);
        return {
          ...i,
          created_at: new Date(i.created_at).toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        }
      })

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar administradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/user/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as PreProposal)
      // load(page);
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as PreProposal)
    }
  }, [selectedItem, toast, page])

  const closeModal = useCallback(() => {
    setSelectedItem({} as PreProposal)
  }, [])

  return (
<Can permissions={['proposal.list', 'proposal.create', 'proposal.all']} page>
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Usuário"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black.primary">
              Propostas
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por cliente" />

            {/* <Button
              as="a"
              href="/pre-proposal/create"
              size="sm"
              fontSize="sm"
              bg="black.primary"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar nova pré-proposta
            </Button> */}

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de pré-propostas</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center">Cliente</Th>
                    <Th textAlign="center">Unidade</Th>
                    <Th textAlign="center">Proposta</Th>

                    <Th textAlign="center">Data de Solicitação</Th>
                    <Th textAlign="center">Status</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i?.net?.name || 'Rede removida.'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i?.unit?.name || 'Unidade removida.'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i?.number}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center">{i?.created_at}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

                          {isWideScreen
                            ? (
                              <Button
                              href={`/proposal/create/${i.id}`}
                                as="a"
                                size="sm"
                                fontSize="sm"
                                mr="2"
                                bg="wine.200"
                                colorScheme="wine"
                                leftIcon={
                                  <Icon as={RiEye2Fill} fontSize="16" />
                                }
                              >
                              Visualizar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ProposalList }
