import { UserProps } from '../Hooks/Auth'

interface ValidateProps {
  user: UserProps
  permissions?: string[]
  roles?: string[]
}

export function validateUserPermissions ({
  user,
  permissions,
  roles
}: ValidateProps): boolean {
  if (!user) {
    return false
  }

  if (permissions?.length) {
    const hasAllPermissions = permissions.some((i) =>
      user?.permissions?.includes(i)
    )
    if (!hasAllPermissions) {
      return false
    }
  }

  // if (!!roles?.length) {
  //   const hasAllPermissions = roles.some((i) => user?.roles?.includes(i));
  //   if (!hasAllPermissions) {
  //     return false;
  //   }
  // }

  return true
}
