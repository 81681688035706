/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineDownload, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { User } from '../Users'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'

export interface TagsProps {
  index: string
  id: string
  user_id?: string
  user?: User
  file: {
    id: string
    ref_id: string
    created_at: string
    link: string
  }
}

const TagsList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<TagsProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as TagsProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/tags', {
        params: {
          pag
        }
      })

      const normalize = data.data.map((i: TagsProps) => {
        return {
          ...i,
          file: {
            ...i?.file,
            created_at: i?.file?.created_at ? format(new Date(i.file.created_at), 'dd/MM/yyyy') : '--'
          }

        }
      })

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar categorias: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    if (!selectedItem || !selectedItem.id) return

    const { id } = selectedItem
    try {
      await api.delete(`/tags/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as TagsProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as TagsProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as TagsProps)
  }, [])

  const handleDownload = (fileUrl: any, name: string) => {
    fetch(fileUrl)
      .then(async (response) => await response.blob())
      .then((blob) => {
        saveAs(blob, `${name}.pdf`)
      })
      .catch((error) => console.error('Error downloading the file', error))
  }

  // const handleDownload = (url: string) => {
  //   fetch(url, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/pdf'
  //     }
  //   })
  //     .then(async (response) => await response.blob())
  //     .then((blob) => {
  //       const urlBlob = window.URL.createObjectURL(blob)
  //       const anchorTag = document.createElement('a')
  //       anchorTag.href = urlBlob
  //       anchorTag.download = url.substr(url.lastIndexOf('/') + 1)
  //       document.body.appendChild(anchorTag)
  //       anchorTag.click()
  //       document.body.removeChild(anchorTag)
  //       window.URL.revokeObjectURL(urlBlob)
  //     })
  //     .catch((error) => console.error('Error downloading the file', error))
  // }

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Tag"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
    <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">

        <Sidebar />

        <Box flex="1"
          borderRadius={8}
          bg="white"
          p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black">
              Tags
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            {/* <SearchBox placeholder="Buscar por nome" /> */}

            <Button
              as="a"
              size="sm"
              fontSize="sm"
              bg="black"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              href="/tags/create"
            >
              Criar novas Tags
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr bg="black">
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th color="white">Índice</Th>
                    <Th color="white">Tag</Th>
                    <Th color="white">Usuário</Th>
                    <Th color="white">Data de Criação</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id}>
                      {/* <Td px={["4","4","6"]}>
                                         <Checkbox colorScheme="pink" />
                                     </Td> */}
                      <Td>
                        <Box>
                          <Link color="black">
                            <Text fontWeight="bold">{i.index}</Text>
                          </Link>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Link color="black">
                            <Text fontWeight="bold">{i.id}</Text>
                          </Link>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Link color="black">
                            <Text fontWeight="bold">{i?.user?.name || 'Tag ainda não atribuída'}</Text>
                          </Link>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Link color="black">
                            <Text fontWeight="bold">{i?.file?.created_at}</Text>
                          </Link>
                        </Box>
                      </Td>

                      <Td>
                        <Flex>

                            <Button
                              as="a"
                              disabled={!i?.file?.link}
                              onClick={i?.file?.link ? () => handleDownload(i.file.link, i.id) : () => {} }
                              size="sm"
                              fontSize="sm"
                              colorScheme="blue"
                              cursor="pointer"
                              leftIcon={
                                <Icon as={AiOutlineDownload} fontSize="16" />
                              }
                              marginRight="10px"
                            >
                              PDF
                            </Button>

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
      </Flex>
      </Flex>

    </Box>
  )
}

export { TagsList }
