import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { Can } from '../../Components/Can'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Loader } from '../../Components/Loader'
import { Select } from '../../Components/Form/Select'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

interface Nets {
  id: string
  internal_code: string
  name: string
}

// const fakeData = [
//   {
//     id: '1',
//     cod: '322',
//     company_name: 'Mc Donalds',
//     unity_quantity: '350',
//     process_quantity: '1.590'
//   },
//   {
//     id: '2',
//     cod: '523',
//     company_name: 'Subway',
//     unity_quantity: '150',
//     process_quantity: '990'
//   },
//   {
//     id: '3',
//     cod: '754',
//     company_name: 'Madero',
//     unity_quantity: '60',
//     process_quantity: '55'
//   }
// ]

const uploadSchema = Yup.object().shape({
  application: Yup.string().required('Selecione a aplicação')
})

const optionsApplications = [
  {
    label: 'Matriz',
    value: '2688405978258'
  },
  {
    label: 'Indústria',
    value: '2688413644917'
  },
  {
    label: 'Ecommerce',
    value: '3359997639999'
  }
]

const TimesheetCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Nets[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState('')
  const toast = useToast()
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const [initialCover, setInitialCover] = useState(undefined)
  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })
  const [processSuccess, setProcessSuccess] = useState(false)

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    resolver: yupResolver(uploadSchema)
  })

  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(async (values) => {
    setLoading(true)
    try {
      if (!DragRef?.current?.selectedFile) {
        toast({
          title: 'Nenhuma planilha selecionada',
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'red'
        })
        setLoading(false)
        return
      }

      DragRef.current?.execute('/files', () => {
        toast({
          title: 'Planilha sendo processada.',
          position: 'top-right',
          isClosable: true,
          status: 'info',
          variant: 'solid'
        })

        setProcessSuccess(true)
        setLoading(false)
      })

      // const { data } = await api.get('/nets', {
      //   params: {
      //     pag
      //   }
      // })

      // setPage(pag)
      // setData(data.data)
      // setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setLoading(false)
      setError(true)
    }
  }, [toast])

  // useEffect(() => {
  //   load(page)
  // }, [page])

  console.log('selectedItem', selectedItem)
  return (
    <Can page>
    <Box>
      {loading && <Loader />}

      <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">
        <Sidebar />
          <Box flex="1" borderRadius={8} bg="white" p="8" as="form"
          onSubmit={handleSubmit(handleSubmitForm)}>
            <Flex mb="8" justify="space-between" align="center">
              <Heading size="lg" fontWeight="normal" color="black.primary">
                Upload de Planinha - SKU / CMC / PREÇO
              </Heading>

            </Flex>

            <Box >
              {processSuccess
                ? <Text my="50px">Sua planilha está sendo processada. Dentro de alguns minutos as alterações estarão sincronizadas com a OMIE.</Text>
                : (
                  <>
                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                      <Select label="Aplicação" placeholder='Selecione a aplicação' options={optionsApplications} {...register('application')} errors={errors?.application} onChange={e => setSelectedItem(e?.target?.value)} />

                      <DragAndDrop
                        label="Planilha"
                        title="Arraste a Planilha"
                        ref={DragRef}
                        defaultCover={initialCover}
                        payload={{
                          application: selectedItem
                        }}
                        errorCbFunction={() => setLoading(false)}

                      />
                    </SimpleGrid>

                      <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                      <Button
                       type="submit"
                        size="sm"
                        fontSize="sm"
                        bg="blue.500"
                        _hover={{ bgColor: 'gray.500' }}
                        color="white"
                        leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                        marginTop="40px"

                      >
                        Processar Planilha
                      </Button>
                      </SimpleGrid>
                  </>
                  )}

            </Box>
          </Box>
        </Flex>
      </Flex>

    </Flex>

    </Box>
    </Can>
  )
}

export { TimesheetCreate }
