/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { format } from 'date-fns'
import { Loader } from '../../Components/Loader'
import NotificationsProps, { NotificationsType } from './Interfaces/NotificationsProps'
import { NOTIFICATIONS_TYPE } from '../../Services/contants'

export type PaymentsType = 'HONORARY' | 'TAX' | 'REFUND'

interface Props extends NotificationsProps {
  typeLabel: string
}

const NotificationsList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Props[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const toast = useToast()

  const NOTIFICATIONS_TYPE_LABEL: any = {
    'pre-proposal-approvement-boss-sector': 'Nova Pré-proposta',
    'provider-create-boss-sector': 'Aprovação de Prestador',
    info: 'Informativo'
  }

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const updateViewed = useCallback(async (notification_id: string) => {
    try {
      await api.patch(`/notifications/${notification_id}`)
    } catch (error) {
      console.log('erro ao atualizar status da notificação: ', error)
      setError(true)
    }
  }, [])

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/notifications', {
        params: {
          pag
        }
      })

      const normalize: any = await Promise.all(data?.data?.map(async (i: any) => {
        if (i?.viewed === 0 && i?.type === NOTIFICATIONS_TYPE.info) {
          await updateViewed(i.id)
        }

        return {
          ...i,
          typeLabel: NOTIFICATIONS_TYPE_LABEL[i.type]
        }
      }))

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar notificações: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [NOTIFICATIONS_TYPE_LABEL, updateViewed])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const makeBg = useCallback((status: string) => {
    return '#f8de7e'
    // switch (status) {
    //   case status = 'REQUESTED':
    //     return '#f8de7e'

    //   case status = 'PAID':
    //     return '#5dbb63'

    //   case status = 'REFUSED':
    //     return '#fa8072'

    //   default:
    //     return '#F5F50C'
    // }
  }, [])

  const makeColor = useCallback((status: string) => {
    return '#993b58'
    // switch (status) {
    //   case status = 'REQUESTED':
    //     return '#993b58'

    //   case status = 'PAID':
    //     return '#fff'

    //   case status = 'REFUSED':
    //     return '#fff'

    //   default:
    //     return '#fff'
    // }
  }, [])

  const MakeActions = (type: NotificationsType, item: NotificationsProps) => {
    if (type === 'pre-proposal-approvement-boss-sector') {
      return <Button
      href={`/proposal/create/${item?.params?.proposal_id}?notification_id=${item?.id}&action=${item?.type}`}
        as="a"
        size="sm"
        fontSize="sm"
        mr="2"
        bg="wine.200"
        colorScheme="wine"
        leftIcon={
          <Icon as={RiEye2Fill} fontSize="16" />
        }
      >
      Visualizar
    </Button>
    }
  }

  return (
    <Box>

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black.primary">
              Notificações
            </Heading>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Loader />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de notificações</Text>
            </Flex>
                )
              : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    <Th textAlign="center">Notificação</Th>
                    <Th textAlign="center">Tipo</Th>
                    <Th textAlign="center">Ações</Th>

                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id} backgroundColor={makeBg('a')}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor('a')}>{i.message}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor('a')}>{i.typeLabel}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>

                      {isWideScreen
                        ? MakeActions(i.type, i)
                        : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
                )}
        </Box>
      </Flex>
    </Box>
  )
}

export { NotificationsList }
