import { Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import Brand from "../../Assets/brand-big.png";

const NotFoundPage: FC = () => {
  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex w="100%" maxWidth={360} p="8" flexDir="column" borderRadius={16}>
        <Stack
          spacing="8"
          color="white"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={Brand} alt="Brand" w="90%" m="auto" mb="4" />
          <Text color="white.whiteAlpha.900" mt="10">
            Página não encontrada
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
};

export { NotFoundPage };
