import React, { FC, useCallback, useState } from 'react'
import IPaymentProps from '../../Pages/BillsToPay/Interfaces/ProcessIntefaces'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Text, Box, Heading, SimpleGrid, AccordionPanel, Divider, Icon, Flex } from '@chakra-ui/react'
import { FiCheck, FiX } from 'react-icons/fi'
import { FilesProps } from '../../Pages/Process/Interfaces/ProcessIntefaces'
import { statusOptions, typeOptions } from '../../Pages/BillsToPay/create'
import { Checkbox } from '../Form/Checkbox'
import { useForm } from 'react-hook-form'
import { Select } from '../Form/Select'

interface Props {
  data: IPaymentProps[]
}

const actionStatusOptions = [
  {
    value: 'REQUESTED',
    label: 'Selecionar'
  },
  {
    value: 'PAID',
    label: 'Efetuar Pagamento de todas selecionadas'
  }
]
const AccordionMultiPayments: FC<Props> = ({ data }) => {
  const [receiptService, setReceiptService] = useState({} as FilesProps)
  const [allBillsCheckedToProcess, setAllBillsCheckedToProcess] = useState<string[]>([])
  const [showPaidModal, setShowPaidModal] = useState(false)
  const [showRefuseModal, setShowRefuseModal] = useState(false)

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })

  const handleSelectBill = useCallback((e: any, id: string) => {
    const checked = e?.target?.checked
    if (checked) {
      setValue(id, true)
      setAllBillsCheckedToProcess(prev => [...prev, id])
    } else {
      const filtered = allBillsCheckedToProcess.filter((i: string) => i !== id)
      setValue(id, false)
      setAllBillsCheckedToProcess(filtered)
      setValue('name-item-all_service_selected', false)
    //   setAllSelected(false)
    }
  }, [setValue, allBillsCheckedToProcess])

  const MakeColor = (i: 'REQUESTED' | 'PAID' | 'REFUSED' | undefined) => {
    const colors = {
      REQUESTED: 'green',
      PAID: 'wine.500',
      REFUSED: 'red'
    }

    return i ? colors[i] : 'orange'
  }

  const handleChangePaymentStatus = useCallback((e: any) => {
    const selectValue = e?.target?.value

    if (selectValue === 'PAID') {
      setShowPaidModal(true)
    }

    if (selectValue === 'REFUSED') {
      setShowRefuseModal(true)
    }
  }, [])

  const closeModal = useCallback(() => {
    setShowPaidModal(false)
  }, [])

  return (

    <>
                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Dados do Pagamento
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mb="15">

                {data[0]?.bank?.pix && <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Chave Pix</Text>
                    <Text align="center">{data[0]?.bank?.pix}</Text>
                </Box>}

                {data[0]?.bank?.bank && (
                  <>
                    <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Banco</Text>
                      <Text align="center">{data[0]?.bank?.bank}</Text>
                    </Box>

                    <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Agência</Text>
                      <Text align="center">{data[0]?.bank?.agency}</Text>
                    </Box>

                    <Box>
                      <Text color="black.primary" fontWeight={500} align="center">Conta</Text>
                      <Text align="center">{data[0]?.bank?.account}</Text>
                    </Box>

                  </>

                )}

              </SimpleGrid>

              <Heading size="md" fontWeight="bold" color="gray.500" my="10">
                      Todos os pagamentos em abertos:
              </Heading>

        <Accordion allowMultiple>
            {data?.map(item => (
                 <AccordionItem key={item.id}>
                 <Flex alignItems="center" flex={1}>
                    <Box>
                 {allBillsCheckedToProcess.includes(`name-item-service_selected-${item.id}`) ? <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${item.id}`)} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelectBill(e, `name-item-service_selected-${item.id}`)}/> : <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${item.id}`)} onChange={(e: any) => handleSelectBill(e, `name-item-service_selected-${item.id}`)} /> }
                 </Box>

                   <AccordionButton flex={1}>
                     <Box as="span" flex={1} textAlign='left'>
                       {item.valueFormatted} - {item?.service?.name}
                     </Box>
                     <AccordionIcon />
                   </AccordionButton>
                 </Flex>

                 <AccordionPanel>
                 <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Dados Operacionais
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Nº Processo/OS</Text>
                    <Text align="center">{item?.process?.cod_prefecture}</Text>
                </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Nº Proposta</Text>
                    <Text align="center">{item?.proposal?.number}</Text>
                </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Rede</Text>
                    <Text align="center">{item?.net?.name}</Text>
                </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Unidade</Text>
                    <Text align="center">{item?.unit?.name}</Text>
                </Box>

              </SimpleGrid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Box>
                  <Text color="black.primary" fontWeight={500} align="center">Tipo</Text>
                  <Text align="center">{typeOptions[item?.typeLabel]}</Text>
              </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Serviço</Text>
                    <Text align="center">{item?.service?.name}</Text>
                </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Valor</Text>
                    <Text align="center" fontWeight="bold">{item?.valueFormatted}</Text>
                </Box>

                <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Nome</Text>
                    <Text align="center">{item?.provider?.name}</Text>
                </Box>

                {/* <Box>
                    <Text color="black.primary" fontWeight={500} align="center">Verificado</Text>
                    <Text align="center">{item?.provider?.active === 0 ? <Icon as={FiCheck} fontSize="24" color="#03ac13" /> : <Icon as={FiX} fontSize="24" color="#FF0000" />}</Text>
                </Box> */}

              </SimpleGrid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

              <Box />
              <Box />
              <Box />
              </SimpleGrid>

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Nota Fiscal / Comprovante de Execução
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                  {/* {receiptService?.extension === 'pdf'
                    ? (
                      <a href={receiptService?.link} target="_blank" rel="noreferrer">
                        <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                          <Document file={receiptService.link}>
                            <Page pageNumber={1} height={200} width={200} />
                          </Document>
                        </div>
                      </a>
                      )
                    : <Box w={250}>
                        <a href={receiptService?.link} target="_blank" rel="noreferrer">
                        <Image src={receiptService?.link} width={250} mb={8}/></a>
                      </Box>} */}

              </SimpleGrid>
                 </AccordionPanel>

               </AccordionItem>
            ))}

      </Accordion>

        <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mt="80px" >

        <Box>
            <Text color="black.primary" fontWeight={500} >Estado atual</Text>
            <Text fontWeight={700} fontSize={20} color={MakeColor(data[0]?.statusLabel || undefined)}>{statusOptions?.find(i => i.value === data[0]?.statusLabel)?.label }</Text>
        </Box>

        {/* {paymentData?.refused_reason && <Box>
            <Text color="black.primary" fontWeight={500} >Motivo</Text>
            <Text fontWeight={400} fontSize={16} >{paymentData?.refused_reason}</Text>
        </Box>} */}

        {data[0]?.statusLabel === 'REQUESTED' && <Select label='Ação de Pagamento' {...register('status')} options= {actionStatusOptions} defaultValue={data[0]?.statusLabel} onChange={handleChangePaymentStatus} />
        }

        </SimpleGrid>
      </>
  )
}

export { AccordionMultiPayments }
