import { Box, HStack, Icon, IconButton, Spinner, useToast } from '@chakra-ui/react'
import { RiNotificationLine, RiUserAddLine } from 'react-icons/ri'
import { AiOutlineLogout } from 'react-icons/ai'
import { useAuth } from '../../Hooks/Auth'
import { useNavigate, useNavigation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import api from '../../Services/api'
import { Loader } from '../Loader'
import NotificationsProps from '../../Pages/Notifications/Interfaces/NotificationsProps'
import { NotificationModal } from '../NotificationModal'

export function NotificationsNav () {
  const [loading, setLoading] = useState(false)
  const [countUnviewedNotifications, setCountUnviewedNotifications] = useState(0)
  const [priorityNotifications, setPriorityNotifications] = useState<NotificationsProps>({} as NotificationsProps)
  const { signOut } = useAuth()
  const navigate = useNavigate()
  const toast = useToast()
  // console.log('header called')

  // const load = useCallback(async () => {
  //   try {
  //     setLoading(true)
  //     const { data: dados } = await api.get('/notifications', {
  //       params: {
  //         pag: 1,
  //         viewed: 0
  //       }
  //     })

  //     console.log('dados: ', dados)

  //     const findPriority = dados?.data?.find((i: any) => i?.priority)

  //     if (findPriority?.id) {
  //       setPriorityNotifications(findPriority)
  //     }

  //     setCountUnviewedNotifications(dados?.data?.length)
  //   } catch (error) {
  //     toast({
  //       title: error.response.data.message,
  //       position: 'top',
  //       isClosable: true,
  //       status: 'error',
  //       variant: 'solid'
  //     })
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [toast])

  // useEffect(() => {
  //   load()
  // }, [])

  const handleCloseNotification = useCallback(async (notification: NotificationsProps) => {
    try {
      setLoading(true)
      await api.patch(`/notifications/${notification?.id}`)
      setPriorityNotifications({} as NotificationsProps)
      // load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast])

  return (
    <HStack
      spacing={['6', '8']}
      mx={['6', '8']}
      pr={['6', '8']}
      py="1"
      color="black.primary"
      borderRightWidth={1}
      borderColor="black.primary"
    >

      {priorityNotifications?.id && <NotificationModal isOpen notification={priorityNotifications} nextFunction={async () => await handleCloseNotification(priorityNotifications)} />}

      {loading ? <Spinner /> : (
              <>
                   <Box position="relative" display="inline-block">
      <IconButton
        icon={<Icon as={RiNotificationLine} fontSize="20" />}
        onClick={() => navigate('/notifications')}
        aria-label="Notifications"
        backgroundColor="transparent"
      />

      {countUnviewedNotifications > 0 && (
         <Box
         position="absolute"
         top="-5px"
         right="-5px"
         rounded="full"
         bg={'green.500'} // Pode alterar a cor do badge aqui
         color="black.primary"
         fontSize="10px"
         width="20px"
         height="20px"
         display="flex"
         justifyContent="center"
         alignItems="center"
         fontWeight={700}
       >
         {countUnviewedNotifications}
       </Box>
      )}

      </Box>

      <IconButton
        icon={<Icon as={AiOutlineLogout} fontSize="20" />}
        onClick={signOut}
        aria-label="Sign Out"
        backgroundColor="transparent"
      />
              </>
      )}

    </HStack>
  )
}
