/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { removeNonNumeric } from '../../utils/removeNonNumeric'
import { Loader } from '../../Components/Loader'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { FilesProps } from '../../Pages/Process/Interfaces/ProcessIntefaces'
import { Can } from '../../Components/Can'

interface Provider {

  id: string
  cod: string
  name: string
  cpf: string
  cnpj: string
  address_id: string
  office: string
  requirement_field: string
  tel: string
  phone: string
  email: string
  obs: string
  bank_id: string
  created_at: string
  address: {

    id: string
    cep: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    state: string

  }
  bank: {

    id: string
    bank: string
    agency: string
    account: string
    pix: string
  }
  files: FilesProps[]
  approved_boss?: string
  approved_financial?: string
}

interface OptionsProps {
  label: string
  value: string
}

export interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createProviderFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido'),
  type_access: Yup.string().required('Selecione o tipo do prestador'),
  tel: Yup.string(),
  phone: Yup.string(),
  cep: Yup.string(),
  street: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    }),
  bank: Yup.string(),
  account: Yup.string(),
  agency: Yup.string(),
  pix: Yup.string()
})

const optionsTypeAccess = [
  {
    value: 'pf',
    label: 'Pessoa Física'
  },
  {
    value: 'pj',
    label: 'Pessoa Jurídica'
  }
]

const optionsJob = [
  {
    value: 'arquiteto',
    label: 'Arquiteto'
  },
  {
    value: 'engenheiro',
    label: 'Engenheiro'
  },
  {
    value: 'despachante',
    label: 'Despachante'
  },
  {
    value: 'motoboy',
    label: 'Motoboy'
  },
  {
    value: 'contador',
    label: 'Contador'
  },
  {
    value: 'corretor',
    label: 'Corretor'
  },
  {
    value: 'outro',
    label: 'Outro'
  }
]

const ProvidersCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const [accessTypeSelected, setTypeAccessSelected] = useState('')
  const [jobSelected, setJobSelected] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const navigate = useNavigate()
  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    resolver: yupResolver(createProviderFormSchema)
  })
  const { errors } = formState
  const router = useLocation()

  const [files, setFiles] = useState<FilesProps[]>()
  const [initialCNHCover, setInitialCNHCover] = useState<undefined | string>(undefined)
  const [initialResidenceCover, setInitialResidenceCover] = useState<undefined | string>(undefined)
  const [initialBankCover, setInitialBankCover] = useState<undefined | string>(undefined)
  const [initialPaymentForThirdCover, setInitialPaymentForThirdCover] = useState<undefined | string>(undefined)
  const [initialSocialContractCover, setInitialSocialContractCover] = useState<undefined | string>(undefined)
  const [initialReceiptCNPJCover, setInitialReceiptCNPJCover] = useState<undefined | string>(undefined)
  const [provider, setProvider] = useState<Provider>({} as Provider)
  const DragCNHRef = useRef<DragAndDropExportedProps>(null)
  const DragResidenceRef = useRef<DragAndDropExportedProps>(null)
  const DragBankRef = useRef<DragAndDropExportedProps>(null)
  const DragPaymentForThirdRef = useRef<DragAndDropExportedProps>(null)
  const DragSocialContractRef = useRef<DragAndDropExportedProps>(null)
  const DragReceiptCNPJRef = useRef<DragAndDropExportedProps>(null)

  //   useEffect(() => {
  //   if (router.query.user_id) {
  //     loadItem();
  //   }
  // }, [router.query]);

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        name,
        email,
        type_access,
        tel,
        phone,
        cpf,
        cnpj,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        obs,
        bank,
        agency,
        account,
        pix,
        office,
        requirement_field
      } = values

      if (!office && type_access === 'pf') {
        toast({
          title: 'Preencha a Profissão',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      if (office && type_access === 'pf' && !requirement_field) {
        toast({
          title: 'Preencha todos os campos',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      // const normalizePhone = String(`${phone}`.replace(/([^0-9]+)/g, ''))

      // if (normalizePhone.length < 11) {
      //   toast({
      //     title: 'Telefone inválido',
      //     position: 'top',
      //     isClosable: true,
      //     status: 'error',
      //     variant: 'solid'
      //   })
      //   return
      // }

      const payload = {
        name,
        email,

        tel,
        phone,
        cpf,
        cnpj,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        obs,
        bank,
        agency,
        account,
        pix,
        office,
        requirement_field
      }

      let id = params?.provider_id

      try {
        if (id) {
          await api.put(`/providers/${id}`, payload)
        } else {
          const { data } = await api.post('/providers', payload)
          id = data?.provider?.id
        }

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragCNHRef, DragResidenceRef, DragBankRef, DragPaymentForThirdRef, DragSocialContractRef, DragReceiptCNPJRef]

        console.log('allFiles:', allFiles)

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                navigate('/providers')
                setLoading(false)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${id}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          navigate('/providers')
          setLoading(false)
        }
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [toast, navigate, params]
  )

  const loadItem = useCallback(async (id: string) => {
    try {
      const resp = await api.get(`/providers/${id}`)
      const data: Provider = resp.data

      setValue('name', data.name)
      setValue('email', data.email)

      setValue('tel', data.tel)
      setValue('phone', data.phone)
      setValue('obs', data.obs)
      setValue('office', data.office)
      setValue('requirement_field', data.requirement_field)
      // setValue('gender', data.gender)
      // setValue('password', data.password)

      if (data?.office) {
        setJobSelected(data?.office)
      }

      if (data.cpf) {
        setTypeAccessSelected('pf')
        setValue('type_access', 'pf')

        const normalizeCpf = removeNonNumeric(data.cpf)
        setValue('cpf', normalizeCpf)
      }

      if (data.cnpj) {
        setTypeAccessSelected('pj')
        setValue('type_access', 'pj')

        const normalizeCnpj = removeNonNumeric(data.cnpj)
        setValue('cnpj', normalizeCnpj)
      }

      setValue(
        'cep',
        String(`${data.address.cep}`.replace(/([^0-9]+)/g, ''))
      )
      setValue('street', data.address.street)
      setValue('number', data.address.number)
      if (data?.address?.complement) {
        setValue('complement', data.address.complement)
      }
      setValue('neighborhood', data.address.neighborhood)
      setValue('city', data.address.city)
      setValue('state', data.address.state)

      setValue('bank', data?.bank?.bank || '')
      setValue('agency', data?.bank?.agency || '')
      setValue('account', data?.bank?.account || '')
      setValue('pix', data?.bank?.pix || '')

      setInitialCNHCover(data.files.find(i => i.type === 'cnh-ou-identidade')?.link)
      setInitialResidenceCover(data.files.find(i => i.type === 'comprovante-residencia')?.link)
      setInitialBankCover(data.files.find(i => i.type === 'confirmacao-dados-bancarios')?.link)
      setInitialPaymentForThirdCover(data.files.find(i => i.type === 'declaracao-pagamento-terceiros')?.link)
      setInitialSocialContractCover(data.files.find(i => i.type === 'contrato-social')?.link)
      setInitialReceiptCNPJCover(data.files.find(i => i.type === 'comprovante-inscricao-cnpj')?.link)
      setFiles(data.files)
      setProvider(data)

      const creationDate = format(new Date(data.created_at), 'dd/MM/yyyy')
      setCreatedAt(creationDate)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  useEffect(() => {
    if (params?.provider_id) {
      loadItem(params?.provider_id)
    }
  }, [params])

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = removeNonNumeric(e?.target?.value)

      if (Number(cep.length) === 8) {
        setLoading(true)

        try {
          const response = await api.get(`/cep/${cep}`)
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data

          setShowAddressComponent(true)

          setValue('street', logradouro)
          setValue('cep', cep)
          setValue('neighborhood', bairro)
          setValue('state', uf)
          setValue('city', localidade)

          if (complemento) {
            setValue('complement', complemento)
          }
        } catch (error) {
          console.log('error users cep: ', error)
          toast({
            title: error.response.data.message,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [setValue, toast]
  )

  const handleChangeType = useCallback((e: any) => {
    const type = e.target.value
    if (accessTypeSelected !== type) {
      setJobSelected('')
    }

    if (type === 'pf') {
      unregister('cnpj')
      setJobSelected('')
    }

    if (type === 'pj') {
      unregister('cpf')
    }

    setTypeAccessSelected(type)
  }, [accessTypeSelected, unregister])

  const handleCheckDocumento = useCallback(async (e: string) => {
    console.log('e: ', e)
    setLoading(true)
    try {
      const { data } = await api.patch(`/providers/check?document=${e}`)

      if (data?.exists) {
        toast({
          title: 'Já existe um prestador cadastrado com este CPF/CNPJ.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    } catch (error) {
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast])

  return (
    <Can permissions={['providers.create', 'providers.all']} page>
    <Box>
      <Header />
      {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="black.primary">
            Criar novo Prestador
          </Heading>
          <Divider my="6" borderColor="black.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais

            {createdAt && <Text size="sm" fontSize="14">Data de inclusão: {createdAt}</Text> }
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <Input
                label="Nome do Prestador"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

              <Select label="Tipo" placeholder="Selecionar tipo" {...register('type_access')} options={optionsTypeAccess} onChange={e => handleChangeType(e)} errors={errors.type_access} />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

               {accessTypeSelected === 'pj' && (
                 <InputMaskCustom
                 type="text"
                 label="CNPJ"
                 errors={errors.cnpj}
                 {...register('cnpj')}
                 placeholder="Digite o CNPJ"
                 mask="99.999.999/9999-99"
                 onBlur={async (e: any) => await handleCheckDocumento(e?.target?.value)}
               />
               )}

                {accessTypeSelected === 'pf' && (
                  <InputMaskCustom
                    type="text"
                    label="CPF"
                    errors={errors.cpf}
                    {...register('cpf')}
                    placeholder="Digite o CPF"
                    mask="999.999.999-99"
                    onBlur={async (e: any) => await handleCheckDocumento(e?.target?.value)}
                  />
                )}

              <InputMaskCustom
                type="text"
                label="Telefone"
                errors={errors.tel}
                {...register('tel')}
                placeholder="Digite o Telefone"
                mask="(99)99999-9999"
              />

              <InputMaskCustom
                type="text"
                label="Celular"
                errors={errors.phone}
                {...register('phone')}
                placeholder="Digite o celular"
                mask="(99)99999-9999"
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            {accessTypeSelected === 'pf' && (
                <Select label="Profissão" placeholder="Selecionar tipo" {...register('office')} options={optionsJob} onChange={e => setJobSelected(e.target.value)} errors={errors.office} />
            )}

          {jobSelected === 'arquiteto' && accessTypeSelected === 'pf' && (
            <Input
              label="Número de Registro (CAU)"
              placeholder="Preencha o número"
              errors={errors.requirement_field}
              {...register('requirement_field')}
            />
          )}

          {jobSelected === 'engenheiro' && accessTypeSelected === 'pf' && (
            <Input
              label="Número de Registro (CREA)"
              placeholder="Preencha o número"
              errors={errors.requirement_field}
              {...register('requirement_field')}
            />
          )}

          {['despachante', 'motoboy', 'outro', 'corretor', 'contador'].includes(jobSelected) && accessTypeSelected === 'pf' && (
             <TextArea
              label="Motivo da contratação"
              placeholder="Preencha o motivo"
              errors={errors.requirement_field}
              {...register('requirement_field')}
            />
          )}

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Email"
                placeholder="Preencha o email"
                errors={errors.email}
                {...register('email')}
              />
            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados de Endereço
          </Heading>

          <VStack spacing={['6', '8']}>
          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <InputMaskCustom
                type="text"
                label="CEP"
                errors={errors.cep}
                {...register('cep')}
                placeholder="Digite o CEP"
                mask="99999-999"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={handleSearchCep}
              />

              <Input
                label="Endereço"
                placeholder="Preencha o Endereço"
                errors={errors.street}
                {...register('street')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="Número"
                placeholder="Preencha o Número"
                errors={errors.number}
                {...register('number')}
              />

<Input
                label="Complemento"
                placeholder="Preencha o complemento"
                errors={errors.complement}
                {...register('complement')}
              />

              <Input
                label="Bairro"
                placeholder="Preencha o bairro"
                errors={errors.neighborhood}
                {...register('neighborhood')}
              />

<Input
                label="Cidade"
                placeholder="Preencha a Cidade"
                errors={errors.city}
                {...register('city')}
              />

<Input
                label="UF"
                placeholder="Preencha o UF"
                errors={errors.state}
                {...register('state')}
              />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados adicionais
          </Heading>

          <VStack spacing={['6', '9']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <TextArea
              label="Observações sobre o Prestador"
              placeholder="Preencha as observações"
              errors={errors.obs}
              {...register('obs')}
            />

            </SimpleGrid>
          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados Bancários
          </Heading>

          <VStack spacing={['6', '9']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Banco"
                placeholder="Preencha o banco"
                errors={errors.bank}
                {...register('bank')}
              />

<Input
              label="Agência"
              placeholder="Preencha a agência"
              errors={errors.agency}
              {...register('agency')}
            />

<Input
              label="Conta"
              placeholder="Preencha a conta"
              errors={errors.account}
              {...register('account')}
            />

<Input
              label="Pix"
              placeholder="Preencha a chave pix"
              errors={errors.pix}
              {...register('pix')}
            />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Anexos
          </Heading>

          {accessTypeSelected === 'pf' && (
            <>
          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            <DragAndDrop
                ref={DragCNHRef}
                label="CNH ou Carteira de Identidade"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'CNH ou Carteira de Identidade',
                  type: 'cnh-ou-identidade'
                }}
                defaultCover={initialCNHCover}
                id={files?.length ? files.find(i => i.type === 'cnh-ou-identidade')?.id : null}
              />

              <DragAndDrop
                ref={DragResidenceRef}
                label="Comprovante de Residência"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Comprovante de Residência',
                  type: 'comprovante-residencia'
                }}
                defaultCover={initialReceiptCNPJCover}
                id={files?.length ? files.find(i => i.type === 'comprovante-residencia')?.id : null}
              />

              </SimpleGrid>
             <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </>
          )}

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            <DragAndDrop
                ref={DragBankRef}
                label="Confirmação de Dados Bancários"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Confirmação de Dados Bancários',
                  type: 'confirmacao-dados-bancarios'
                }}
                defaultCover={initialBankCover}
                id={files?.length ? files.find(i => i.type === 'confirmacao-dados-bancarios')?.id : null}
              />

              <DragAndDrop
                ref={DragPaymentForThirdRef}
                label="Declaração pagamento para terceiros"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Declaração pagamento para terceiros',
                  type: 'declaracao-pagamento-terceiros'
                }}
                defaultCover={initialPaymentForThirdCover}
                id={files?.length ? files.find(i => i.type === 'declaracao-pagamento-terceiros')?.id : null}
              />

            </SimpleGrid>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          {accessTypeSelected === 'pj' && (
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <DragAndDrop
                ref={DragSocialContractRef}
                label="Contrato Social"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Contrato Social',
                  type: 'contrato-social'
                }}
                defaultCover={initialSocialContractCover}
                id={files?.length ? files.find(i => i.type === 'contrato-social')?.id : null}
              />

<DragAndDrop
                ref={DragReceiptCNPJRef}
                label="Comprovante de Inscrição do CNPJ"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Comprovante de Inscrição do CNPJ',
                  type: 'comprovante-inscricao-cnpj'
                }}
                defaultCover={initialSocialContractCover}
                id={files?.length ? files.find(i => i.type === 'comprovante-inscricao-cnpj')?.id : null}
              />

          </SimpleGrid>
          )}

          {!provider?.approved_boss && !provider.approved_financial && (
            <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="black.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
          )}

        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ProvidersCreate }
