import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  FormHelperText,
  InputLeftAddon,
  InputGroup,
  Text
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface Props extends ChakraInputProps {
  name: string
  label?: string
  errors?: any
  helperText?: string
  leftAddon?: boolean
  leftAddonText?: string
  IconCustom?: any
  labelStyle?: any
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    name,
    label,
    errors = null,
    helperText,
    leftAddon,
    leftAddonText,
    IconCustom,
    labelStyle,
    ...rest
  },
  ref
) => (
  <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel color="black.primary" style={labelStyle}>{label}</FormLabel>}

    {leftAddon ? (
      <InputGroup bg="white">
        <InputLeftAddon bgColor="wine.500" color="white" height="48px">
          <Text color="white.primary" fontWeight="bold">
            {leftAddonText}
          </Text>
        </InputLeftAddon>
        <Input
          name={name}
          id={name}
          size="lg"
          bg="white"
          variant="filled"
          border="1px"
          borderColor="black.primary"
           _hover={{ bgColor: 'white' }}
           _focus={{ bgColor: 'white' }}
          _placeholder={{ color: 'gray.300' }}
          focusBorderColor="black.primary"
          color="black.primary"
          ref={ref}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
          {...rest}
        />
      </InputGroup>
    ) : (
      <ChakraInput
        name={name}
        id={name}
        size="lg"
        bg="white"
        variant="filled"
        border="1px"
        borderColor="wine.500"
        color="black.primary"
        _hover={{ bgColor: 'white' }}
        _focus={{ bgColor: 'white' }}
        // _hover={{ bgColor: "wine.100"}}
        _placeholder={{ color: 'gray.300' }}
        focusBorderColor="wine.500"
        ref={ref}
        {...rest}
      />
    )}

    {helperText && (
      <FormHelperText color="gray.500">{helperText}</FormHelperText>
    )}
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
)

export const Input = forwardRef(BaseInput)
