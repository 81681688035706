/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useEffect, useState } from 'react'
import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Container,
  FormLabel,
  forwardRef,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { motion, useAnimation } from 'framer-motion'
import { AiFillSave, AiOutlineDelete } from 'react-icons/ai'
import api from '../../Services/api'
import { Document, Page, pdfjs } from 'react-pdf'
import { RiEye2Fill } from 'react-icons/ri'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  defaultCover: string
  label: string
}

const ReceiptPreview: FC<Props> = ({ defaultCover, label }) => {
  const [pdfFile, setPdfFile] = useState<any>(null)
  const [previewExtension, setPreviewExtension] = useState('')
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState<string | undefined>(undefined)
  const [currentCover, setCurrentCover] = useState('')

  useEffect(() => {
    if (!selectedFile && !currentCover) {
      setPreview(undefined)
      return
    }

    if (currentCover) {
      const lastDotIndex = currentCover.lastIndexOf('.')
      const extension = currentCover.substring(lastDotIndex + 1)

      setPreviewExtension(extension)

      if (extension === 'pdf') {
        setPdfFile(currentCover)
      }
    }

    const objectUrl = selectedFile
      ? URL.createObjectURL(selectedFile)
      : defaultCover

    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, defaultCover, currentCover])

  useEffect(() => {
    if (defaultCover) {
      setCurrentCover(defaultCover)
    }
  }, [defaultCover])

  return (
    <Container>
      {!!label && <FormLabel>{label}</FormLabel>}

      {pdfFile && (
        <>
          <div style={{ overflow: 'hidden', width: 200, height: 200 }}><Document file={pdfFile}>
            <Page pageNumber={1} height={200} width={200} />
          </Document>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {currentCover && (
                <Button
                href={`${currentCover}`}
                    target="_blank"
                    as="a"
                    size="sm"
                    fontSize="sm"
                    mr="2"
                    bg="wine.200"
                    colorScheme="wine"
                    mt="5"
                    width={150}
                    leftIcon={<Icon as={RiEye2Fill} fontSize="16" />}
                >
                Visualizar
                </Button>
            )}
          </div>
        </>
      )
      }

      {preview && previewExtension !== 'pdf' &&
        (
          <>

            <img src={preview} style={{ width: 200 }} />

            {currentCover && (
              <Button
                href={`${currentCover}`}
                target="_blank"
                as="a"
                size="sm"
                fontSize="sm"
                mr="2"
                bg="wine.200"
                colorScheme="wine"
                mt="5"
                width={150}
                leftIcon={
                  <Icon as={RiEye2Fill} fontSize="16" />
                }
              >Visualizar</Button>
            )}

          </>
        )}

      {/* {!preview && !pdfFile && (
        <AspectRatio width="64" ratio={1}>
         <Box
           borderColor="gray.300"
           borderStyle="dashed"
           borderWidth="2px"
           rounded="md"
           shadow="sm"
           role="group"
           transition="all 150ms ease-in-out"
           _hover={{
             shadow: 'md'
           }}
           as={motion.div}
           initial="rest"
           animate="rest"
           whileHover="hover"
           cursor="pointer"
         >
           <Box
             position="relative"
             height="100%"
             width="100%"
             cursor="pointer"
           >
             <Box
               position="absolute"
               top="0"
               left="0"
               height="100%"
               width="100%"
               display="flex"
               flexDirection="column"
             >
               <Stack
                 height="100%"
                 width="100%"
                 display="flex"
                 alignItems="center"
                 justify="center"
                 spacing="4"
               >
                 <Box height="16" width="12" position="relative">
                   <PreviewImage
                     variants={first}
                     backgroundImage="url('https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg')"
                   />
                   <PreviewImage
                     variants={second}
                     backgroundImage="url('https://images.unsplash.com/photo-1565299585323-38d6b0865b47?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2628&q=80')"
                   />
                   <PreviewImage
                     variants={third}
                     backgroundImage={'url("https://images.unsplash.com/photo-1563612116625-3012372fccce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2480&q=80")'}
                   />
                 </Box>
                 <Stack p="8" textAlign="center" spacing="1">
                   <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                     Arraste as imagens
                   </Heading>
                   <Text fontWeight="light">ou clique para carregar</Text>
                 </Stack>
               </Stack>
             </Box>
             <Input
               type="file"
               height="100%"
               width="100%"
               position="absolute"
               top="0"
               left="0"
               opacity="0"
               aria-hidden="true"
               // accept="image/*"
               onDragEnter={startAnimation}
               onDragLeave={stopAnimation}
               onChange={async (e) => await handleChangeInput(e)}
             />
           </Box>
         </Box>
        </AspectRatio>
      )} */}

    </Container>
  )
}

export { ReceiptPreview }
