import React, { FC } from "react";

import { AuthProvider } from "./Auth";
import { SideBarProvider } from "./SideBarContext";

const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <AuthProvider>
    <SideBarProvider>{children}</SideBarProvider>
  </AuthProvider>
);

export default AppProvider;
