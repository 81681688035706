import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { format } from 'date-fns'
import { Can } from '../../Components/Can'

type PaymentsStatus = 'CREATED' | 'REQUESTED' | 'PAID' | 'REFUSED'
export type PaymentsType = 'HONORARY' | 'TAX' | 'REFUND'

interface Payments {
  id: string
  value: string
  installment: string
  created_at: string
  allowed_at: string | null
  value_formatted: string
  typeLabel: PaymentsType
  statusLabel: PaymentsStatus
  process: {
    id: string
    code: string
  }
  provider: {
    id: string
    name: string
  }
  net: {
    id: string
    name: string
  }
  unit: {
    id: string
    name: string
  }
}

const BillsToReceiveList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Payments[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState<Payments>({} as Payments)
  const toast = useToast()

  const STATUS = {
    REQUESTED: 'Solicitado',
    PAID: 'Pago',
    REFUSED: 'Recusado',
    CREATED: 'Criado'
  }

  const TYPE = {
    HONORARY: 'Honorários',
    TAX: 'Taxa',
    REFUND: 'Reembolso'
  }

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/payments', {
        params: {
          pag,
          status: [0, 1, 2, 3],
          paymentKind: 1 // contas à receber
        }
      })

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar administradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/process/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as Payments)
      // load(page);
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as Payments)
    }
  }, [selectedItem, toast])

  const closeModal = useCallback(() => {
    setSelectedItem({} as Payments)
  }, [])

  const makeBg = useCallback((status: PaymentsStatus) => {
    switch (status) {
      case status = 'CREATED':
        return '#f8de7e'
      case status = 'REQUESTED':
        return '#f8de7e'

      case status = 'PAID':
        return '#5dbb63'

      case status = 'REFUSED':
        return '#fa8072'

      default:
        return '#F5F50C'
    }
  }, [])

  const makeColor = useCallback((status: PaymentsStatus) => {
    switch (status) {
      case status = 'CREATED':
        return '#993b58'
      case status = 'REQUESTED':
        return '#993b58'

      case status = 'PAID':
        return '#fff'

      case status = 'REFUSED':
        return '#fff'

      default:
        return '#fff'
    }
  }, [])

  return (
    <Can permissions={['bills_to_receive.list', 'bills_to_receive.create', 'bills_to_receive.all']} page>
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Processo"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black.primary">
              Contas à Receber
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por cliente" />

            {/* <Button
              as="a"
              href="/pre-proposal/create"
              size="sm"
              fontSize="sm"
              bg="black.primary"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar nova pré-proposta
            </Button> */}

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de pagamentos</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center">Rede</Th>
                    <Th textAlign="center">Unidade</Th>
                    <Th textAlign="center">Nº Processo</Th>
                    <Th textAlign="center">Valor</Th>
                    <Th textAlign="center">Data de Criação</Th>
                    <Th textAlign="center">Status</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id} backgroundColor={makeBg(i.statusLabel)}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i.net.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i.unit.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i.process.code}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i.value_formatted}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" fontSize={13} color={makeColor(i.statusLabel)}>{i?.created_at}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" color={makeColor(i.statusLabel)}>{STATUS[i.statusLabel]}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

{isWideScreen
  ? (
                              <Button
                              href={`/bills-receive/create/${i.id}`}
                                as="a"
                                size="sm"
                                fontSize="sm"
                                mr="2"
                                bg="wine.200"
                                colorScheme="wine"
                                leftIcon={
                                  <Icon as={RiEye2Fill} fontSize="16" />
                                }
                              >
                              Visualizar
                            </Button>
    )
  : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
    )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { BillsToReceiveList }
