/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useParams } from 'react-router-dom'
import { TagsProps } from '../Tags'
import { User } from '../Users'

interface createUserProps {
  index?: number
  name: string
  email: string
  born: string
  phone: string
  gender: string
  gym_id: string
  password?: string
  zip_code?: string
  street?: string
  number?: string
  complement?: string
  neighborhood?: string
  city?: string
  state?: string
  cpf?: string
}

interface OptionsProps {
  label: string
  value: string
}

interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido')
    .required('E-mail é obrigatório'),
  born: Yup.string().required('Data de nascimento é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),

  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade'),
  zip_code: Yup.string(),
  street: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('zip_code', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    })
})

interface CustomUser extends User {
  tags: TagsProps[]
}

const UsersProfile: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const [data, setData] = useState({} as CustomUser)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<createUserProps> = useCallback(
    async (values) => {
      const {
        name,
        email,
        password,
        gym_id,
        born,
        phone,
        gender,
        street,
        zip_code,
        number,
        complement,
        neighborhood,
        city,
        state,
        cpf
      } = values

      const normalizePhone = String(`${phone}`.replace(/([^0-9]+)/g, ''))

      if (normalizePhone.length < 11) {
        toast({
          title: 'Telefone inválido',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        name,
        email,
        password: password || '123123',
        gym_id,
        born,
        phone: normalizePhone,
        gender,
        street,
        zip_code,
        number,
        complement,
        neighborhood,
        city,
        state,
        cpf
      }

      //   const { user_id } = router.query;

      try {
        // user_id
        //   ? await api.put(`/user/${user_id}`, payload)
        //   : await api.post("/user", payload);
        const user_id = 'aa'

        toast({
          title: `${user_id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        // router.push("/users");
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast]
  )

  const loadItem = useCallback(async () => {
    try {
      //   const { user_id } = router.query;
      //   const resp = await api.get(`/user/${user_id}`);
      //   const data: User = resp.data;
      //   const born = parseISO(data.born);
      //   console.log("born: ", born);
      //   const bornNormalized = format(born, "dd/MM/yyyy");
      //   setValue("name", data.name);
      //   setValue("email", data.email);
      //   setValue("born", data.born ? bornNormalized : "");
      //   setValue(
      //     "phone",
      //     data.phone ? String(`${data.phone}`.replace(/([^0-9]+)/g, "")) : ""
      //   );
      //   // setValue('born', '01081989')
      //   setValue("cpf", data.cpf || "");
      //   // setValue('gender', data.gender)
      //   // setValue('password', data.password)
      //   setValue("gym_id", data.gym_id);
      //   if (data?.address?.zip_code) {
      //     setShowAddressComponent(true);
      //     setValue(
      //       "zip_code",
      //       String(`${data.address.zip_code}`.replace(/([^0-9]+)/g, ""))
      //     );
      //     setValue("street", data.address.street);
      //     setValue("number", data.address.number);
      //     if (data?.address?.complement) {
      //       setValue("complement", data.address.complement);
      //     }
      //     setValue("neighborhood", data.address.neighborhood);
      //     setValue("city", data.address.city);
      //     setValue("state", data.address.state);
      //   }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  const load = useCallback(async () => {
    try {
      const resp = await api.get(`/users/${params?.id}`)

      setData(resp.data)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, params])

  useEffect(() => {
    if (params?.id) {
      load()
    }
  }, [params])

  //   useEffect(() => {
  //     if (router.query.user_id) {
  //       loadItem();
  //     }
  //   }, [router.query]);

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = `${e}`.replace(/([^0-9]+)/g, '')

      if (Number(cep.length) === 8) {
        setLoading(true)
        toast({
          title: 'Buscando Endereço',
          position: 'top',
          isClosable: true,
          status: 'info',
          variant: 'solid'
        })
        try {
          const response = await api.get(`/cep/${cep}`)
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data
          console.log('busca cep: ', response.data)

          setShowAddressComponent(true)

          setValue('street', logradouro)
          setValue('neighborhood', bairro)
          setValue('state', uf)
          setValue('city', localidade)

          if (complemento) {
            setValue('complement', complemento)
          }
        } catch (error) {
          console.log('error users cep: ', error)
          toast({
            title: error.response.data.message,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [setValue, toast]
  )

  return (
    <Box>
          <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">

        <Sidebar />

        <Box
          as="form"
          //   onSubmit={handleSubmit(handleSubmitForm)}
          onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="black">
            Perfil Usuário
          </Heading>
          <Divider my="6" borderColor="blue.700" />

            <VStack spacing={['6', '8']} mb="50px">
                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" >

                        <Box >
                            <Heading size="md" fontWeight="bold" color="green.primary" my="6">
                                Dados Cadastrais
                            </Heading>

                            <Text fontSize="15px" color="gray.800"><b>Nome:</b> {data?.name}</Text>
                            <Text fontSize="15px" color="gray.500"><b>E-mail:</b> {data?.email || 'Não informado'}</Text>
                            <Text fontSize="15px" color="gray.500"><b>Fone:</b> {data?.tel || 'Não informado'}</Text>
                            <Text fontSize="15px" color="gray.800"><b>Time:</b> {data?.team}</Text>
                        </Box>

                        <Box >
                            <Heading size="md" fontWeight="bold" color="green.primary" my="6">
                                Dados de Endereço
                            </Heading>

                            <Text fontSize="15px" color="gray.800">Rua: {data?.name}</Text>
                            <Text fontSize="15px" color="gray.500">Número: {data?.email || 'Não informado'}</Text>
                            <Text fontSize="15px" color="gray.500">Comlemento: {data?.tel || 'Não informado'}</Text>
                            <Text fontSize="15px" color="gray.800">Bairro: {data?.team}</Text>
                            <Text fontSize="15px" color="gray.800">Cidade: {data?.team}</Text>
                            <Text fontSize="15px" color="gray.800">Estado: {data?.team}</Text>
                        </Box>

                </SimpleGrid>

            </VStack>

            <Heading size="md" fontWeight="normal" color="black" my="6">
                              Tags
                            </Heading>

                            <Table colorScheme="blue">
                <Thead>
                  <Tr bg="black">
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th color="white">Índice</Th>
                    <Th color="white">Tag</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.tags?.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                        <Box>

                            <Text fontWeight="bold">{i.index}</Text>

                        </Box>
                      </Td>
                      <Td>
                        <Box>

                            <Text fontWeight="bold">{i.id}</Text>

                        </Box>
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>

          {/* <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="black"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex> */}
        </Box>
        </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export { UsersProfile }
