import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  isCurrent?: boolean
  number: number
  onPageChange: (page: number) => void
}

export function PaginationItem ({ isCurrent = false, onPageChange, number }: PaginationItemProps) {
  if (isCurrent) {
    return (
            <Button
                size="sm"
                fontSize="xs"
                w="4"
                colorScheme="pink"
                color="gray.50"
                disabled
                _disabled={{
                  bgColor: 'teal',
                  cursor: 'default'
                }}
            >{number}</Button>
    )
  }

  return (
        <Button
        size="sm"
        fontSize="xs"
        w="4"
        colorScheme="pink"
        bg="gray.700"
        _hover={{
          bg: 'blue.400'
        }}
        color="gray.50"
        onClick={() => onPageChange(number)}
         >{number}</Button>
  )
}
