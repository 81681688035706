import React, { FC, useCallback } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TextArea } from '../Form/TextArea'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
  refused?: boolean
  initialRefusedReason?: string
}
const PaymentRefuseModal: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  description,
  cancelText,
  nextText,
  cancelScheme,
  nextScheme,
  thirdButton,
  thirdButtonText,
  thirdButtonFunction,
  refused = false,
  initialRefusedReason
}) => {
  const cancelRef = React.useRef<any>()

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      const { refused_reason } = values
      console.log('refused_reason: ', refused_reason)

      nextFunction(refused_reason)
    },
    [nextFunction]
  )

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="5xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <Text pl={6}>{refused ? initialRefusedReason : 'Digite o motivo pelo qual o pagamento foi recusado:'} </Text>

          {refused
            ? <AlertDialogFooter>

                <Button colorScheme={cancelScheme} onClick={cancelFunction}>
                  {cancelText || 'Cancelar'}
                </Button>
                <Button
                      bg="wine.200"
                      colorScheme="wine"
                  onClick={nextFunction}
                  ml={3}
                >
                  Reprocessar Pagamento
                </Button>
          </AlertDialogFooter>
            : (
              <Box
              as="form"
                onSubmit={handleSubmit(handleSubmitForm)}

              flex="1"
              borderRadius={8}
              bg="white"
              p="8"
            >

              <AlertDialogBody p={0}>

                  <TextArea
                    label="Motivo da recusa"
                    placeholder="Preencha o motivo"
                    {...register('refused_reason')}
                  />

              </AlertDialogBody>

              <AlertDialogFooter>
                {thirdButton && (
                  <Button onClick={thirdButtonFunction}>
                    {thirdButtonText || 'Cancelar'}
                  </Button>
                )}

                <Button colorScheme={cancelScheme} onClick={cancelFunction}>
                  {cancelText || 'Cancelar'}
                </Button>
                <Button
                      bg="wine.200"
                      colorScheme="wine"
                  type="submit"
                  ml={3}
                >
                  Prosseguir
                </Button>
              </AlertDialogFooter>
              </Box>
              )}

        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { PaymentRefuseModal }
